/* eslint-disable eqeqeq */ /* eslint-disable eqeqeq */
<template>
  <div class="msg-main">
    <div class="msg-body">
      <div class="chatroom">
        <div class="chat-left">
          <h2>Message</h2>
          <div class="d-flex justify-content-center" v-if="isFetchingDriver">
            <div class="spinner-border text-primary" role="status"></div>
          </div>
          <div class="chat-list" v-if="connectedDriver.length > 0 && !isFetchingDriver">
            <div class="form-group selection pb-1">
              <select class="form-select" @change="selectBooking($event)">
                <option value="">All Booking</option>
                <option
                  v-for="option in this.title"
                  v-bind:key="option.referenceId"
                  v-bind:value="option.referenceId"
                >
                  <span v-if="option.title.length < 50">{{ option.title }}</span>
                  <span v-else>{{ option.title.substring(0, 50) + '..' }}</span>
                </option>
              </select>
            </div>
            <div
              class="chat-list-item"
              v-for="(driver, index) in connectedDriver"
              v-bind:key="index"
              @click="
                clearPrviousTypigChat();
                showChat(driver);
              "
              style="cursor: pointer"
              :class="selectedDriver && selectedDriver.id == driver.id ? 'chatSelected' : ''"
            >
              <div class="chat-user-top">
                <div
                  class="user-profile"
                  :class="`${OnlineusersData.includes(driver.sender_id) ? 'onlineAvatar' : ''}`"
                >
                  <figure class="profile">
                    <avatar
                      v-if="driver.bind_driver_detail.image_url == null"
                      :username="driver.bind_driver_detail.full_name"
                      class="w-auto h-100"
                    ></avatar>
                    <img
                      v-if="driver.bind_driver_detail.image_url != null"
                      :src="driver.bind_driver_detail.image_url"
                      alt=""
                    />
                  </figure>
                  <span v-if="driver.count_unread_message > 0">
                    {{ driver.count_unread_message }}
                  </span>
                </div>
                <div class="user-right">
                  <div class="ur-top">
                    <div class="user-info">
                      <h4>{{ driver.bind_driver_detail.full_name }}</h4>
                      <p
                        class="d-flex"
                        v-if="['file', 'image'].includes(driver.get_last_message.message_type)"
                      >
                        <span v-show="driver.get_last_message.attechment_extension == 'pdf'">
                          <i
                            class="fas fa-file-pdf mr-3"
                            style="
                              cursor: pointer;
                              color: #959a9c;
                              font-size: 1.5rem;
                              margin-right: 10px;
                            "
                          ></i>
                        </span>
                        <span v-show="driver.get_last_message.attechment_extension != 'pdf'">
                          <i
                            class="fas fa-image mr-3"
                            style="
                              cursor: pointer;
                              color: #959a9c;
                              font-size: 1.5rem;
                              margin-right: 10px;
                            "
                          ></i>
                        </span>
                        <span v-if="driver.get_last_message.message">{{
                          driver.get_last_message.message
                        }}</span>
                        <span v-else>{{ driver.get_last_message.attechment_name }}</span>
                      </p>
                      <p v-else>{{ driver.get_last_message.message }}</p>
                    </div>
                    <div class="time-info">{{ moment(driver.updated_at).fromNow() }}</div>
                    <!-- <div class="time-info">
                      <b>{{ driver.booking_detail.booking_reference_id }}</b>
                    </div> -->
                  </div>
                  <!-- <div class="ur-bottom">
                  <ul>
                    <li>
                      <figure>
                         <img :src="require('@assets/images/pin-sharp-circle.png')" />
                      </figure>
                    </li>
                  </ul>
                </div> -->
                </div>
              </div>
              <div class="chat-user-bottom-info">
                <div class="track">
                  <ul>
                    <li>
                      <figure>
                        <img :src="require('@assets/images/pin-sharp-circle.svg')" alt="" />
                      </figure>
                      <div class="track-location">
                        <!-- <h4>Pyrmont, NSW</h4> -->
                        <h4>{{ driver.booking_detail.pickup_address }}</h4>
                      </div>
                    </li>
                    <li v-if="driver.booking_detail.drop_address">
                      <figure>
                        <img :src="require('@assets/images/pin-sharp-dot.svg')" alt="" />
                      </figure>
                      <div class="track-location">
                        <!-- <h4>Mosman, NSW</h4> -->
                        <h4>{{ driver.booking_detail.drop_address }}</h4>
                      </div>
                    </li>
                  </ul>
                  <div class="budget">
                    <h4>{{ moment(driver.booking_detail.service_date).format('dddd') }}</h4>
                    <div class="date-info">
                      <figure>
                        <img :src="require('@assets/images/gray-calander.svg')" alt="" />
                      </figure>
                      {{ moment(driver.booking_detail.service_date).format('D-MMM-YYYY') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="!isFetchingDriver" style="text-align: center">
            <img
              class="noDataImage"
              :src="require('@/assets/images/nodata.svg')"
              alt=""
            /><br /><br />
            <b>Connected driver not found</b>
          </div>
        </div>
        <div class="chat-right" v-if="receiverId != 0">
          <div class="cr-header">
            <div class="cuser" :class="`${isDriverOnline ? 'onlineAvatar' : ''}`">
              <figure v-if="driverProfile == null">
                <div class="msg-avtar-wrap">
                  <avatar :username="driverName" class="avatar"> </avatar>
                </div>
              </figure>
              <figure v-else-if="driverProfile != null">
                <img :src="driverProfile" />
              </figure>
              <div class="cu-info">
                <h5>
                  {{ driverName }}
                  <router-link
                    :to="{
                      name: 'bookings',
                      params: { from: 'alert-modal', booking_id: selectedDriver.booking_detail.id },
                    }"
                    tag="a"
                  >
                    ({{ selectedDriver.booking_detail.booking_reference_id }})
                  </router-link>
                </h5>
                <span v-if="isDriverOnline">online</span>
                <span v-else>offline</span>
              </div>
            </div>
          </div>
          <div
            class="cr-body"
            :class="attechment_url ? 'image-attached' : ''"
            v-if="chatData.length > 0"
            @scroll="infiniteScroll"
          >
            <div
              :class="chat.reciver_id == logginUserId ? 'msg-left' : 'msg-right'"
              v-for="(chat, index) in chatData"
              v-bind:key="index"
            >
              <!-- sender -->
              <div class="cr-msg" v-if="chat.reciver_id == logginUserId">
                <!-- sender profile -->
                <avatar v-if="driverProfile == null" :username="driverName" class="avatar"></avatar>
                <figure v-else-if="driverProfile != null">
                  <img :src="driverProfile" />
                </figure>

                <!-- image or pdf -->
                <div v-if="chat.attechment_url && chat.message">
                  <div class="chat-info">
                    <div class="chat_image">
                      <img
                        :src="chat.attechment_url"
                        alt=""
                        style="cursor: pointer !important"
                        @click="openModalForShowImage(chat.attechment_url)"
                      />
                    </div>
                    <a :href="chat.attechment_url" target="_blank"></a>
                    <span>{{ chat.message }}</span>
                  </div>
                </div>
                <div v-else>
                  <img
                    class="chat_image"
                    v-show="
                      chat.attechment_url &&
                      chat.attechment_url != null &&
                      chat.attechment_extension != 'pdf'
                    "
                    :src="chat.attechment_url"
                    alt=""
                    style="cursor: pointer !important"
                    @click="openModalForShowImage(chat.attechment_url)"
                  />
                  <a :href="chat.attechment_url" target="_blank">
                    <img
                      class="chat_image"
                      v-show="
                        chat.attechment_url &&
                        chat.attechment_url != null &&
                        chat.attechment_extension == 'pdf'
                      "
                      :src="require('@assets/images/pdf-file.png')"
                      alt=""
                  /></a>
                  <!-- chat message -->
                  <p v-if="chat.message">{{ chat.message }}</p>
                </div>
              </div>
              <!-- receiver -->
              <div class="cr-msg" v-else>
                <!-- image or pdf -->
                <div class="chat-info">
                  <img
                    class="chat_image"
                    v-if="
                      chat.attechment_url &&
                      chat.attechment_url != null &&
                      chat.attechment_extension != 'pdf'
                    "
                    :src="chat.attechment_url"
                    alt=""
                    style="cursor: pointer !important"
                    @click="openModalForShowImage(chat.attechment_url)"
                  />
                  <a :href="chat.attechment_url" target="_blank">
                    <img
                      class="chat_image"
                      v-if="
                        chat.attechment_url &&
                        chat.attechment_url != null &&
                        chat.attechment_extension == 'pdf'
                      "
                      :src="require('@assets/images/pdf-file.png')"
                      alt=""
                  /></a>
                  <!-- chat message -->
                  <p v-if="chat.message">
                    {{ chat.message }}
                  </p>
                </div>
                <!-- receiver profile -->
                <avatar
                  v-if="profileImage == null"
                  :username="logginUserName"
                  class="avatar"
                ></avatar>
                <figure v-else-if="profileImage != null">
                  <img :src="profileImage" />
                </figure>
              </div>
              <div class="msg-info">
                <span>{{ moment(chat.created_at).format('hh:mm A') }}</span>
                <img
                  data-bs-toggle="tooltip" data-bs-html="true"
                  :title="chat.message_status == 'read' ? 'read' : 'delivered'"
                  v-if="chat.reciver_id != logginUserId"
                  style=""
                  :src="
                    chat.message_status == 'read'
                      ? require('@assets/images/double-tick.png')
                      : require('@assets/images/tick.png')
                  "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            class="text-center"
            style="position: absolute; top: 173px; left: 50%"
            v-if="isFetchingChat"
          >
            <div class="spinner-border text-primary" role="status"></div>
          </div>
          <h5 class="text-center mt-5" v-if="chatData.length < 1 && !isFetchingChat">Start chat</h5>
          <div class="cr-write">
            <!-- eslint-disable -->
            <div
              v-show="attechment_url"
              style="
                position: relative;
                background: aliceblue;
                padding: 16px;
                border-top-left-radius: 14px;
                border-top-right-radius: 14px;
                width: 93%;
              "
            >
              <figure>
                <img
                  :src="attechment_url"
                  v-if="attechment_extension != 'pdf'"
                  style="height: 100px; width: 100px"
                />
                <img
                  :src="require('@/assets/images/pdf-file.png')"
                  v-if="attechment_extension == 'pdf'"
                  style="height: 100px; width: 100px"
                />
                <a @click="clearPrviousTypigChat()">
                  <i class="fa fa-times-circle" style="font-size: 24px; cursor: pointer"></i
                ></a>
              </figure>
            </div>
            <div class="crw-wrap" v-on:keyup.enter="sendMessage()">
              <div class="form-group">
                <span>
                  <input
                    type="file"
                    id="imgupload"
                    style="display: none"
                    @change="uploadAttachment"
                    accept="image/jpeg,image/jpg,image/png,application/pdf"
                  />
                  <label for="imgupload"
                    ><img
                      :src="require('@assets/images/photo-camera.svg')"
                      alt=""
                      style="cursor: pointer"
                      data-bs-toggle="tooltip" data-bs-html="true" title="Allowed format are : JPEG, JPG, PNG, PDF"
                  /></label>
                </span>
                <textarea
                  class="form-control"
                  placeholder="write something"
                  v-model="message"
                  ref="messageTextarea"
                >
                </textarea>
              </div>
              <a @click="sendMessage()" class="ms-2" style="color: #00a7e2">
                <i class="fab fa-telegram fa-5x" aria-hidden="true" style="cursor: pointer"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="chat-right" v-else>
          <h3 class="mt-5 text-center">Select driver to start chat</h3>
        </div>
      </div>
    </div>
    <imageShowModal
      :showImageinModalPath="showImageinModalPath"
      v-on:closeImageModel="showImageinModalPath = ''"
    ></imageShowModal>
  </div>
</template>
<script>
import Avatar from 'vue-avatar';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import imageShowModal from '@/components/ImageShowModal.vue';
// eslint-disable-next-line no-unused-vars

export default {
  name: 'message',
  components: { Avatar, imageShowModal },
  data() {
    return {
      connectedDriver: [],
      chatData: [],
      isFetchingDriver: false,
      isFetchingChat: false,
      logginUserId: 0,
      profileImage: null,
      senderId: 0,
      receiverId: 0,
      driverName: '',
      driverProfile: null,
      message: '',
      page: 0,
      lastPage: 0,
      currentPage: 0,
      selectedDriver: null,
      getDataAfterScrool: 0,
      attechment_name: '',
      attechment_extension: '',
      attechment_mime_type: '',
      attechment_url: null,
      messageType: 'text',
      OnlineusersData: [],
      isDriverOnline: 0,
      showImageinModalPath: '',
      title: [],
      connectedDriverStore: [],
    };
  },
  /* eslint-disable */
  mounted() {
    setInterval(() => {
      this.OnlineusersData = [...this.$store.state.Onlineusers];
      if (this.OnlineusersData.includes(this.receiverId)) {
        this.isDriverOnline = 1;
      } else {
        this.isDriverOnline = 0;
      }
    }, 5000);
  },
  /* eslint-enable */
  computed: {
    ...mapState(['Onlineusers']),
  },
  beforeRouteLeave(to, from, next) {
    if (this.selectedDriver) {
      this.$socket.socket.off(`sendto_${this.logginUserId}_${this.selectedDriver.booking_id}`);
    }
    next();
  },
  created() {
    const userdata = JSON.parse(localStorage.getItem('userData'));
    if (userdata) {
      this.logginUserId = userdata.id;
      this.profileImage = userdata.image_url;
      this.logginUserName = userdata.full_name;
    }
    this.getConnectedDriverList();
    this.$socket.socket.onAny((eventName, ...args) => {
      this.allDriverChatListen(eventName, args);
    });
  },
  methods: {
    moment,
    ...mapActions(['connectedList']),
    infiniteScroll(e) {
      const bottomOfWindow = Math.abs(e.target.scrollTop) + e.target.clientHeight + 2
      >= e.target.scrollHeight;
      if (bottomOfWindow && this.lastPage >= this.page) {
        this.getDataAfterScrool = 1;
        this.showChat(this.selectedDriver);
      }
    },
    uploadAttachment(event) {
      if (event && event.target.accept.split(',').includes(event.target.files[0].type)) {
        const formdata = new FormData();
        formdata.append('attachment', event.target.files[0]);
        if (event.target.files[0]) {
          this.$http
            .post('/chat/attachments', formdata, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((res) => {
              this.attechment_url = res.data.data.attechment_url;
              this.attechment_name = this.attechment_url.substring(
                this.attechment_url.lastIndexOf('/') + 1,
              );
              this.attechment_extension = this.attechment_name.split('.').pop();
              this.attechment_mime_type = event.target.files[0].type;
              if (this.attechment_name.split('.').pop() === 'pdf') {
                this.messageType = 'file';
              } else {
                this.messageType = 'image';
              }
              // this.sendMessage();
            })
            .catch((error) => {
              this.$toast.error(error.response.data.message);
            })
            .finally(() => {
              this.$refs.messageTextarea.focus();
            });
        }
      } else {
        this.$toast.error('Please upload valid file type');
      }
    },
    getConnectedDriverList() {
      this.isFetchingDriver = true;
      this.$http
        .get('/chat/connected_driver_list')
        .then((res) => {
          this.connectedDriver = res.data.data;
          this.connectedDriverStore = res.data.data;
          this.title = res.data.data.map((data) => ({
            title: data.booking_detail.title_of_booking,
            referenceId: data.booking_detail.booking_reference_id,
          }));
          /*eslint-disable*/
          if (this.$route.params.bookingId && this.$route.params.sender_id) {
            const messageDriver = res.data.data.find(
              (element) =>
                element.booking_id == this.$route.params.bookingId &&
                element.sender_id == this.$route.params.sender_id,
            );
            this.showChat(messageDriver);
          }
          if (this.$route.params.bookingId && this.$route.params.driver_id) {
            const messageDriver = res.data.data.find(
              (element) =>
                element.booking_id == this.$route.params.bookingId &&
                element.sender_id == this.$route.params.driver_id,
            );
            this.showChat(messageDriver);
          }
        })
        .catch((error) => {
          this.connectedDriver = [];
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isFetchingDriver = false;
        });
    },
    selectBooking(event) {
      if (event.target.value) {
        this.connectedDriver = this.connectedDriverStore.filter(
          (element) => element.booking_detail.booking_reference_id == event.target.value,
        );
      } else {
        this.connectedDriver = this.connectedDriverStore;
      }
    },
    showChat(driver) {
      this.isFetchingChat = true;
      if (this.bookingId !== driver.booking_id) {
        this.chatData = [];
      }
      this.driverName = driver.bind_driver_detail.full_name;
      this.driverProfile = driver.bind_driver_detail.image_url;
      this.receiverId = driver.bind_driver_detail.id;
      this.bookingId = driver.booking_id;
      this.selectedDriver = driver;
      this.selectedDriver.count_unread_message = 0;
      const isScroolData = this.getDataAfterScrool;
      if (isScroolData === 0) {
        this.page = 1;
        this.lastPage = 1;
        this.currentPage = 0;
      }
      if (isScroolData === 1) {
        this.page += 1;
      }
      if (this.page > this.currentPage && this.page <= this.lastPage) {
        this.$http
          .get('/chat/message_list', {
            params: {
              page: this.page,
              perPage: 10,
              booking_id: driver.booking_id,
              user_id: driver.bind_driver_detail.id,
            },
          })
          .then((res) => {
            try {
              this.$socket.socket.off(`sendto_${this.logginUserId}_${driver.booking_id}`);
              this.$socket.socket.on(`sendto_${this.logginUserId}_${driver.booking_id}`, (data) => {
                /* eslint-disable */
                let newMessageArrivedForWhichDriverArray = this.connectedDriver.filter(
                  (cd) => cd.booking_id == data.booking_id,
                );
                if (
                  this.logginUserId == data.reciver_id &&
                  this.selectedDriver.sender_id == data.sender_id &&
                  this.selectedDriver.booking_id == data.booking_id &&
                  this.$route.name == 'message'
                ) {
                  this.selectedDriver.count_unread_message = 0;
                  this.chatData.unshift(data);
                  this.readReceipt();
                }
                /* eslint-disable */
              });
            } catch (error) {
              this.$toast.error(error.response.data.message);
            }

            if (isScroolData === 0) {
              this.chatData = [];
              this.chatData = res.data.data.collection;
            } else if (isScroolData === 1) {
              this.chatData = [...this.chatData, ...res.data.data.collection];
            }
            this.currentPage = +res.data.data.currentPage;
            this.lastPage = res.data.data.lastPage;
            this.readReceipt();
          })
          .catch((error) => {
            if (isScroolData === 0) {
              this.page = 1;
              this.lastPage = 1;
              this.currentPage = 0;
            }
            if (isScroolData === 1) {
              this.page -= 1;
            }
            this.chatData = [];
            this.$toast.error(error.response.data.message);
          })
          .finally(() => {
            this.getDataAfterScrool = 0;
            this.isFetchingChat = false;
          });
      } else {
        this.getDataAfterScrool = 0;
        this.isFetchingChat = false;
      }
    },
    allDriverChatListen(eventName, data) {
      if (this.selectedDriver) {
        this.$socket.socket.on(
          `read_receipt_to_${this.selectedDriver.reciver_id}_${this.selectedDriver.booking_id}`,
          (data) => {
            if(window.location.href.includes('message')) {
              this.chatData.map(function (element) {
                return (element.message_status = 'read');
              });
            }
          },
        );
      }
      if (data[0]) {
        data = data[0];
        if (data.from && data.from == 'driver') {
          let newMessageArrivedForWhichDriverArray = this.connectedDriver.filter(
            (cd) => (cd.booking_id == data.booking_id &&  cd.sender_id == data.sender_id),
          );
          if (
            Array.isArray(newMessageArrivedForWhichDriverArray) &&
            newMessageArrivedForWhichDriverArray.length
          ) {
            newMessageArrivedForWhichDriverArray[0].get_last_message = { ...data };
            newMessageArrivedForWhichDriverArray[0].updated_at = new Date().toISOString();
            // if data found that increase unread count
            newMessageArrivedForWhichDriverArray[0].count_unread_message =
              newMessageArrivedForWhichDriverArray[0].count_unread_message + 1;
          }
        }
      }
    },
    clearPrviousTypigChat() {
      this.message = '';
      this.messageType = 'text';
      this.attechment_extension = '';
      this.attechment_mime_type = '';
      this.attechment_name = '';
      this.attechment_url = null;
    },
    sendMessage() {
      const msgdata = {
        senderId: this.logginUserId,
        receiverId: this.receiverId,
        bookingId: this.bookingId,
        message: this.message.replace(/(\r\n|\n|\r)/gm, ''),
        messageType: this.messageType,
        attechment_extension: this.attechment_extension,
        attechment_mime_type: this.attechment_mime_type,
        attechment_name: this.attechment_name,
        attechment_url: this.attechment_url,
        sender_name: this.logginUserName,
      };
      this.$socket.sendMessage(msgdata);
      if (
        this.message.replace(/(\r\n|\n|\r)/gm, '') ||
        this.messageType === 'image' ||
        this.messageType === 'file'
      ) {
        this.chatData.unshift({
          attechment_extension: this.attechment_extension,
          attechment_mime_type: this.attechment_mime_type,
          attechment_name: this.attechment_name,
          attechment_url: this.attechment_url,
          booking_id: this.bookingId,
          created_at: new Date(),
          deleted_at: null,
          id: null,
          message: this.message,
          message_status: 'unread',
          message_type: this.messageType,
          reciver_id: this.receiverId,
          sender_id: this.logginUserId,
          updated_at: null,
        });
        this.selectedDriver.get_last_message.message = this.message;
        this.selectedDriver.updated_at = new Date().toISOString();
        this.selectedDriver.get_last_message.attechment_name = this.attechment_name;
        this.selectedDriver.get_last_message.attechment_extension = this.attechment_extension;
        this.selectedDriver.get_last_message.message_type = this.messageType;
      }
      this.message = '';
      this.messageType = 'text';
      this.attechment_extension = '';
      this.attechment_mime_type = '';
      this.attechment_name = '';
      this.attechment_url = null;
    },

    readReceipt() {
      const readdata = {
        senderId: this.logginUserId,
        receiverId: this.receiverId,
        bookingId: this.bookingId,
      };
      this.$socket.readReceipt(readdata);
      this.connectedList();
    },

    openModalForShowImage(openModalForShowImage) {
      this.showImageinModalPath = openModalForShowImage;
    },

    closeImageModel() {
      this.showImageinModalPath = '';
    },
  },
};
</script>
<style scoped>
.chat_image {
  width: 100%;
  height: 120px;
  overflow: hidden;
  border-radius: 9px;
}
.chat_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chatSelected {
  background-color: #00a7e212;
  padding-left: 10px;
  transition: all 0.2s ease-in;
}
</style>
